import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import './Products.css';

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('/api/products/stripe-public-key/')
      .then(response => response.json())
      .then(data => {
        setStripePromise(loadStripe(data.publicKey));
      })
      .catch(error => console.error('Error fetching Stripe public key:', error));

    fetch('/api/products/get-products/')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const handlePurchase = async (productId, isSubscription) => {
    setLoading(true);
    if (!stripePromise) {
      console.error('Stripe has not been initialized');
      setLoading(false);
      return;
    }
    const stripe = await stripePromise;

    // Create checkout session
    const response = await fetch('/api/products/create-checkout-session/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId,
      }),
    });

    const session = await response.json();

    if (session.error) {
      console.error('Error:', session.error);
      setLoading(false);
      return;
    }

    console.log(`Redirecting to checkout with session ID: ${session.sessionId}`);

    const { error } = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });

    if (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const formatDescription = (description) => {
    return description.split('\n').map((item, index) => (
      <li key={index}>{item}</li>
    ));
  };

  return (
    <div className="products-container">
      <h1>AU++ Products</h1>
      <div className="product-grid">
        {products.map(product => (
          <div key={product.id} className="product-card">
            {product.image_url && (
              <img 
                src={`${process.env.PUBLIC_URL}${product.image_url}`}
                alt={product.name} 
                className="product-image" 
              />
            )}
            <h2>{product.name}</h2>
            <ul className="product-description">
              {formatDescription(product.description)}
            </ul>
            {product.name !== "Donation" && (
              <p className="product-price">${product.price}</p>
            )}
            <button 
              onClick={() => handlePurchase(product.id, product.is_subscription)} 
              disabled={loading}
              className="purchase-button"
            >
              {product.name === "Donation" ? 'Donate' : (product.is_subscription ? 'Subscribe' : 'Purchase')}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
