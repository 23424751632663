import React from 'react';
import './OrderConfirmation.css';

const OrderConfirmation = () => {
  return (
    <div className="order-confirmation">
      <h1>Thank you for your purchase!</h1>
      <p>Please check your email for an invoice and confirmation.</p>
    </div>
  );
};

export default OrderConfirmation;
